import React, { Component, useRef, useEffect, useState } from 'react';
import {Navbar, Nav, Dropdown} from 'react-bootstrap';
import { observer, inject } from "mobx-react";
import DumbLinkContainer from "./DumbLinkContainer";
import Config from "../Config"
import Api from "./Api";
import styled from '@emotion/styled';
import WindowHelper from "../helpers/WindowHelper";
import {backdropFilterSupportCondition} from "../styles/BackdropFilter"
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";
import {
    CameraIconLightShadowed, CheckmarkIconLightShadowed,
    ClockIconLightShadowed, EmotionIconLightShadowed, StoryIconLightShadowed, ExternalLinkIconLightShadowed,
    FlameIconLightShadowed,
    GalleryIconLightShadowed, GlobeIconLightShadowed, HelpIconLightShadowed,
    ListIconLightShadowed, LogInIconLightShadowed,
    LogoLiveIcon,
    MagnifyIconLightShadowed,
    PhotoMultiIconLightShadowed,
    PlusIconLightShadowed,
    ProfileIconLightShadowed, ShowcaseIconLightShadowed,
    StarFilledIconLightShadowed, EarthIconLightShadowed,
} from "../svg/Icons";
import {SectionHeader} from "../routes/storylist/user/UserStoryList";
import Scroll from "./Scroll";
import {UserImage} from "../routes/gallery/profile/ProfileHeader";
import {DimSpan} from "../styles/Span";
import {FontLoadFadeIn} from "../routes/frontpage/Frontpage";
import {useLocation} from "react-router-dom";
import helpJson from "../help.json"
import {useRouteMatch} from "react-router-dom";

const NavbarHolder = styled.div`
  z-index: 110;
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${WindowHelper.NAV_HEIGHT}px;
  user-select: none;
  box-shadow: 0 0 0 1px rgba(60,60,60,1), 0 0 3px 1px rgba(0,0,0,.5);
  contain: size layout;
  
  @supports ${backdropFilterSupportCondition()} {
    //.navbar, .navbar-inverse {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background: rgba(25, 25, 25, .4);
    //}
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    //.navbar, .navbar-inverse {
      background: rgba(25, 25, 25, .8);
    //}
  }
  
  .navbar-toggler {
    border: none;
  }
`;

const TopIcon = styled.div`
  margin-right: ${p => p.skinny ? 0 : 9}px;
  padding-left: ${p => p.skinny ? 0 : 3}px;
  display: inline-block;
  vertical-align: text-top;
  //position: relative;
  font-size: 0;
  top: -1px;
  opacity: ${p => (p.skinny && !p.lowOpacity) ? 1 : .35};
  transition: opacity 200ms;
`;

const TopIconRight = styled(TopIcon)`
  padding-left: 0;
  padding-right: 0;
  margin-right: ${p => p.skinny ? 0 : 5}px;
  margin-left: 0;
`;

const TopText = styled.span`
  margin-right: ${p => p.thin ? 0 : 9}px;
  ${p => p.thin ? 'vertical-align: -1.5px;' : ''};
`;

const NavLink = styled(Nav.Link)`
  ${typeface(16, 700, -.015)};
  color: ${Theme.colors.almostDim} !important;
  transition: color 200ms;
  height: 100%;
  &:focus {
    color: ${Theme.colors.almostDim} !important;
  }
  &:active {
    color: ${Theme.colors.almostDim} !important;
  }
  &:hover {
    color: ${Theme.colors.almostWhite} !important;
    ${TopIcon} {
      opacity: .75;
    }
  }
  &::after {
    display: block;
    width: 10px;
    top: 34px;
    left: calc(50% - 5px);
    margin-left: 0;
    position: absolute;
    opacity: 0;
    transition: opacity 200ms;
    filter: drop-shadow(0 0 3px rgba(0,0,0,1)) drop-shadow(0 0 2px rgba(0,0,0,1));
  }
  
  &:hover::after {
    opacity: .35;
  }
`;

const DropdownPadding = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const NavLinkUser = styled(NavLink)`
  &:hover {
    img {
      filter: none !important;
    }
  }
  &::after {
    //visibility: hidden;
  }
`;

const NavLinkUserSkinny = styled(NavLinkUser)`
  padding-left: 2px !important;
  padding-right: 2px !important;
`;

const DropdownMenuStyled = styled(Dropdown.Menu)`
  background: rgba(25, 25, 25, .95);
  box-shadow: 0 0 0 1px rgba(255,255,255,.15) inset, 0 0 0px 1px rgba(0,0,0,.3);
  border: none;
  position: fixed !important;
  contain: content;
  padding: 0;
`;

const ScrollDropdown = styled(Scroll)`
  max-height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px - ${p => p.isTouch ? 80 : 10}px);
`;

const DropdownMenu = inject("appState")(observer((props) => {
    let ref = useRef(null);
    let observer = useRef(null);
    let {appState, skinny, children, ...otherProps} = props;

    let update = () => {
        if (ref.current) {
            let parentRect = ref.current.parentElement.getBoundingClientRect();
            let pos;
            if (props.skinny) {
                pos = parentRect.left + parentRect.width/2 - ref.current.offsetWidth/2;
            } else {
                pos = parentRect.left - 16;
            }
            ref.current.style.left = `${Math.min(window.innerWidth - ref.current.offsetWidth, pos)}px`;
        }
    };

    useEffect(() => {
        window.addEventListener("resize", update)
        return () => window.removeEventListener("resize", update);
    });

    useEffect(() => setRef(null), []);

    let setRef = (r) => {
        if (observer.current) {
            observer.current.disconnect();
            observer.current = null;
        }
        ref.current = r;
        if (r) {
            observer.current = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.attributeName === "class"){
                        update();
                    }
                })
            });
            observer.current.observe(r, {attributes: true});
        }
        update();
    };
    update();
    return (
        <DropdownMenuStyled ref={setRef} {...otherProps}>
            <ScrollDropdown isTouch={!!appState.media.touch} showArrow={true}>
                <DropdownPadding>{children}</DropdownPadding>
            </ScrollDropdown>
        </DropdownMenuStyled>
    );
}));

const DropdownMenuRight = styled(DropdownMenu)`
  left: unset;
  right: 0;
`;

const DropdownMenuRightWide = styled(DropdownMenuRight)`
  width: 185px;
`;

const LinkIconDiv = styled.div`
  display: inline-block;
  margin-left: 6px;
  color: ${Theme.colors.dimmer};
`;

const ItemIcon = styled.div`
  margin-right: 14px;
  display: inline-block;
  top: 5px;
  position: relative;
`;

const ItemIconWhite = styled(ItemIcon)`
  @keyframes pulse {
        0% {
          transform: scale(1, 1);
          color: ${Theme.colors.almostDim};
        }
    
        50% {
          transform: scale(1.125, 1.125);
          color: ${Theme.colors.almostWhite};
        }
    
        100% {
          transform: scale(1, 1);
          color: ${Theme.colors.almostDim};
        }
    }
    
    & {
        animation: pulse 1000ms ease-in-out infinite;
    }
`;

const GalleryIconLightShadowedLowOpacity = styled(GalleryIconLightShadowed)`
  opacity: .4;
`;

const StarFilledIconLightShadowedStyled = styled(StarFilledIconLightShadowed)`
  opacity: ${p => p.opacity};
`;

const FlameIconLightShadowedStyled = styled(FlameIconLightShadowed)`
  opacity: ${p => p.opacity};
`;

const WithSpan = styled(DimSpan)`
  ${typeface(12,500,.035)};
`;

const DropdownItem = styled(Dropdown.Item, {shouldForwardProp: p => p !== "padded"})`
  padding-top: ${p => p.padded ? 7 : 4}px;
  padding-bottom: ${p => p.padded ? 7 : 4}px;
  margin-bottom: 1px;
  ${typeface(16, 700)};
  color: ${Theme.colors.almostDim} !important;
  background: none !important;
  outline: none !important;
  &.focus-visible {
    background: none !important;
    outline: none !important;
    color: ${Theme.colors.almostWhite} !important;
    box-shadow: inset 0 0 1px 1px white;
    ${LinkIconDiv} {
      color: ${Theme.colors.almostDim};
    }
  }
  &:active {
    color: ${Theme.colors.almostDim} !important;
    background: none !important;
    outline: none !important;
  }
  &:hover {
    color: ${Theme.colors.almostWhite} !important;
    background: none !important;
    outline: none !important;
    ${LinkIconDiv} {
      color: ${Theme.colors.almostDim};
    }
    ${WithSpan} {
      color: ${Theme.colors.almostDim};
    }
  }
`;

const NavbarBrand = styled(Navbar.Brand)`
  //top: -2px;
  position: relative;
  ${typeface(16, 700)};
  color: ${Theme.colors.almostWhite} !important;
  cursor: pointer;
  margin-left: 20px;
  &:focus {
    color: ${Theme.colors.almostWhite} !important;
  }
  &:active {
    color: ${Theme.colors.almostWhite} !important;
  }
  contain: content;
  width: 108px;
  height: 32px;
`;

const BrandSuper = styled.sup`
  ${typeface(10, 400, .075)};
  color: ${Theme.colors.dim};
  margin-left: 2px;
  top: -5px;
  text-shadow: 0 2px 3px rgba(0,0,0,1);
`;

const LogoHolder = styled.div`
  display: inline-block;
  margin-right: 6px;
  vertical-align: -1.5px;
`;

const LogoPlaceholder = styled.div`
  display: inline-block;
  margin-right: 6px;
  vertical-align: -1.5px;
  width: ${p => p.scale}px;
  height: ${p => p.scale}px;
`;

const LogoText = styled.div`
  display: inline-block;
`;

const LogoOverlay = styled.div`
  position: fixed;
  pointer-events: none;
  top: 5px;
  left: 0px;
  z-index: 150;
  width: 0;
  overflow: visible;
`;

const NavbarStyled = styled(Navbar)`
  padding: 0;
  margin: 0;
  border: none;
  text-shadow: 0 2px 3px rgba(0,0,0,1);
  transform: scale(1);
  height: ${WindowHelper.NAV_HEIGHT}px;
  position: relative;
`;

const NavItem = styled(Nav.Item)`
  position: relative;
  background: none;
  height: 100%;
  &:hover {
    color: ${Theme.colors.kindaDim};
    background: none !important;
  }
  &.show {
    ${NavLink}::after {
      opacity: 1 !important;
    }
    ${NavLinkUser}::after {
      opacity: 1 !important;
    }
    ${NavLinkUser} {
      img {
        filter: none !important;
      }
    }
  }
`;

const DropdownItemClickable = inject("appState")(observer(({className, to, children, appState, onClick, 'data-attr': dataAttr}) => {
    let click = (e) => {
        if (e && (e.metaKey || e.ctrlKey)){
            // allow default
        } else {
            if (e) {
                e.preventDefault();
            }
            appState.history.push(to);
        }
    };
    return <DropdownItem data-attr={dataAttr} className={className} href={to ? to : undefined} padded={appState.media.touch} onClick={onClick ? onClick : click}>
        {children}
    </DropdownItem>;
}));

const DropdownDivider = styled(Dropdown.Divider)`
  border-top: 1px solid rgba(255,255,255,.1);
  margin-top: 12px;
  margin-bottom: 12px;
`;

const DropdownSpacerHolder = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

const DropdownSpacerContent = styled.div`
  background: rgba(255,255,255,.1);
  width: 16px;
  height: 1px;
  border-radius: 1px;
`;

const Spacer = styled.div`
  width: 10px;
`;

const SectionMenuHeader = styled(SectionHeader)`
  padding: 4px;
  color: ${Theme.colors.notQuiteDim};
`;

const SectionMenuHeaderLogin = styled(SectionMenuHeader)`
  margin-bottom: 4px;
  color: ${Theme.colors.kindaDim};
`;

const UserName = styled.div`
  display: inline-block;
  margin-right: 8px;
  padding-right: 20px;
  transform: scale(1);
`;

const UserImageHolder = styled.div`
  display: inline-block;
  border-radius: 14px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .85);
  height: 28px;
  width: 28px;
  position: absolute;
  top: 5px;
  right: 0px;
  transition: opacity 150ms;
  opacity: ${p => p.invisible ? 0 : 1};
`;

const UserImageHolderSkinny = styled.div`
  display: inline-block;
  border-radius: 14px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .85);
  height: 28px;
  width: 28px;
  position: relative;
  transition: opacity 150ms;
  opacity: ${p => p.invisible ? 0 : 1};
`;

const UserSpacer = styled.div`
  height: 20px;
  width: 20px;
`;

const NavStyled = styled(Nav)`
`;

const FontLoadFadeInInline = styled(FontLoadFadeIn)`
  display: inline-block;
`;

const LoginFadeInDiv = styled.div`
  opacity: ${p => p.invisible ? 0 : 1};
  transition: 150ms opacity, 2000ms max-width linear;
  max-width: ${p => p.invisible ? 100 : 500}px;
`;

const LoginSpacer = styled.div`
  width: 100px;
`;

const RightToLeftDiv = styled.div`
  float: right;
`;

const LogInInfo = styled.div`
  color: ${Theme.colors.kindaDim};
  padding: 10px 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogInItems = styled.div`
`;

const LogInItem = styled.div`
  margin: 2px;
`;

const LoginItemText = styled.span`
  margin-left: 8px;
`;

const OrText = styled.span`
  display: inline;
  color: ${Theme.colors.dim};
  ${typeface(11)};
`;

const CheckmarkIconLightShadowedStyled = styled(CheckmarkIconLightShadowed)`
  color: ${Theme.colors.dim};
  background-color: rgba(255,255,255,.065);
  border-radius: 2px;
  padding: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .35);
`;

const LoginInfoDropdownItemClickable = styled(DropdownItemClickable)`
  padding-left: 44px;
`;

const DropdownSpacer = () =>
    <DropdownSpacerHolder>
        <DropdownSpacerContent/>
    </DropdownSpacerHolder>;

const UserImageNavbarComponent = (props) => {
    let [loaded, setLoaded] = useState(false);
    return <UserImageHolder invisible={!loaded}>
        <UserImage {...props} onLoad={() => setLoaded(true)}/>
    </UserImageHolder>
};

const UserImageNavbarSkinnyComponent = (props) => {
    let [loaded, setLoaded] = useState(false);
    return <UserImageHolderSkinny invisible={!loaded}>
        <UserImage {...props} onLoad={() => setLoaded(true)}/>
    </UserImageHolderSkinny>
};

const AppNavbar = (props) => {

    let mapPaths = [
        '/map/:hashtype/:lat/:lng',
        '/map/:hashtype/:hash',
        '/map',
    ];
    let isMap = mapPaths.map((path) => useRouteMatch(path)).some((v) => !!v);


    const listener = async (event) => {
        // TODO: use nonce and verify
        if (event.data.action === "login") {
            Api.saveTokens(event.data.access, event.data.refresh);
        } else if (event.data.action === "failure"){
            console.log("failure");
        }
    };

    useEffect(() => {
        window.addEventListener("message", listener);
        return () => window.removeEventListener("message", listener);
    });

    const loginWith = (provider) => {
        window.open(Config.APP_API_BASE_URL + "/oauth2/authorization/" + provider, "_blank");
    };

    const logout = async () => {
        Api.clearTokens();
    };

    let userUrl;
    if (props.appState.user) {
        if (props.appState.user.alias) {
            userUrl = `/${props.appState.user.alias}`;
        } else {
            userUrl = `/user/_${props.appState.user.ident}`;
        }
    }
    let skinny = props.appState.media.skinny;

    let loc = useLocation();
    let helpTarget = "/help";
    if (loc.pathname.match("^/map(/.*)?$")){
        helpTarget = "/help/map";
    } else if (loc.pathname.match("^/explore/senses(/.*)?$")) {
        helpTarget = "/help/discovery/rankings#Senses-rankings";
    } else if (loc.pathname.match("^/explore/hot(/.*)?$")) {
        helpTarget = "/help/discovery/rankings#Hot-rankings";
    } else if (loc.pathname.match("^/explore/top(/.*)?$")) {
        helpTarget = "/help/discovery/rankings#Top-rankings";
    } else if (loc.pathname.match("^/explore/recent(/.*)?$")) {
        helpTarget = "/help/discovery/rankings#Recent-listing";
    } else if (loc.pathname.match("^/browse(/.*)?$")) {
        helpTarget = "/help/photos/browse";
    } else if (loc.pathname.match("^/upload(/.*)?$")) {
        helpTarget = "/help/photos/uploading";
    } else if (loc.pathname.match("^/favorites(/.*)?$")) {
        helpTarget = "/help/discovery/favorites";
    } else if (loc.pathname.match("^/following(/.*)?$")) {
        helpTarget = "/help/discovery/feed";
    } else if (loc.pathname.match("^/followers(/.*)?$")) {
        helpTarget = "/help/discovery/feed";
    } else if (loc.pathname.match("^/feed(/.*)?$")) {
        helpTarget = "/help/discovery/feed#Your-Feed";
    } else if (loc.pathname.match("^/photo(/.*)?$")) {
        helpTarget = "/help/photos";
    } else if (loc.pathname.match("^/story/_new$")) {
        helpTarget = "/help/stories/blocks";
    } else if (loc.pathname.match("^/story(/.*)?$")) {
        helpTarget = "/help/stories";
    } else if (loc.pathname.match("^/settings/account(/.*)?$")) {
        helpTarget = "/help/account/settings";
    } else if (loc.pathname.match("^/settings/subscription(/.*)?$")) {
        helpTarget = "/help/account/subscription";
    } else if (loc.pathname.match("^/settings/overlay(/.*)?$")) {
        helpTarget = "/help/overlay";
    } else if (loc.pathname.match("^/settings/plugins(/.*)?$")) {
        helpTarget = "/help/plugins";
    } else if (loc.pathname.match("^/blog(/.*)?$")) {
        helpTarget = "/help/overview";
    } else if (loc.pathname.match("^/[a-zA-Z0-9_-]+/photos/unpublished(/.*)?$")) {
        helpTarget = "/help/photos/visibility";
    } else if (loc.pathname.match("^/[a-zA-Z0-9_-]+/photos(/.*)?$")) {
        helpTarget = "/help/photos";
    } else if (loc.pathname.match("^/[a-zA-Z0-9_-]+/stories(/.*)?$")) {
        helpTarget = "/help/stories";
    } else if (loc.pathname.match("^/[a-zA-Z0-9_-]+/showcase(/.*)?$")) {
        helpTarget = "/help/photos/showcase";
    } else if (loc.pathname.match("^/[a-zA-Z0-9_-]+$")) {
        helpTarget = "/help/account/profile";
    } else if (loc.pathname.match("^/$")) {
        helpTarget = "/help/overview";
    }

    return (
        <>
            <LogoOverlay>
                <FontLoadFadeIn>
                    <NavbarBrand>
                        <LogoHolder>
                            <LogoLiveIcon scale={16}/>
                        </LogoHolder>
                    </NavbarBrand>
                </FontLoadFadeIn>
            </LogoOverlay>
            <NavbarHolder>
                <FontLoadFadeIn>
                    <NavbarStyled variant="dark" expand={true}>
                        <DumbLinkContainer to="/" key={"home"} data-attr={"button-brand"}>
                            <NavbarBrand>
                                <LogoPlaceholder scale={16}/>
                                <LogoText>Lumathon</LogoText>
                                {skinny || true ? null : <BrandSuper>beta</BrandSuper>}
                            </NavbarBrand>
                        </DumbLinkContainer>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse>
                            <NavStyled>
                                <Dropdown as={NavItem} key={"explore"}>
                                    <Dropdown.Toggle as={NavLink} data-attr={"menu-explore"}>
                                        <TopIcon skinny={skinny}><PhotoMultiIconLightShadowed scale={skinny ? 24 : 20}/></TopIcon>
                                        {skinny ? null : <TopText>Explore</TopText>}
                                    </Dropdown.Toggle>
                                    <DropdownMenu skinny={skinny}>
                                        {skinny ? <SectionMenuHeader>Explore</SectionMenuHeader> : null}
                                        {props.appState.user ?
                                            <DropdownItemClickable to={`/feed`} data-attr={"button-explore-feed"}>
                                                <ItemIcon><GlobeIconLightShadowed scale={20}/></ItemIcon>
                                                <span>Your Feed</span>
                                            </DropdownItemClickable>
                                            :
                                            <DropdownItemClickable to={`/`}>
                                                <ItemIcon><GlobeIconLightShadowed scale={20} data-attr={"button-explore-frontpage"}/></ItemIcon>
                                                <span>Front Page</span>
                                            </DropdownItemClickable>
                                        }
                                        <DropdownDivider/>
                                        <SectionMenuHeader>Photos</SectionMenuHeader>
                                        <DropdownItemClickable to={`/explore/senses`} data-attr={"button-explore-senses"}>
                                            <ItemIcon><EmotionIconLightShadowed scale={20}/></ItemIcon>
                                            <span>Senses</span>
                                        </DropdownItemClickable>
                                        <DropdownSpacer/>
                                        <DropdownItemClickable to={`/explore/hot/day/photos`} data-attr={"button-explore-hotphotos"}>
                                            <ItemIcon><FlameIconLightShadowedStyled opacity={1} scale={20}/></ItemIcon>
                                            <span>Hot</span>
                                        </DropdownItemClickable>
                                        <DropdownItemClickable to={`/explore/top/week/photos`} data-attr={"button-explore-topphotos"}>
                                            <ItemIcon><StarFilledIconLightShadowedStyled opacity={1} scale={20}/></ItemIcon>
                                            <span>Top</span>
                                        </DropdownItemClickable>
                                        <DropdownItemClickable to={`/explore/recent/photos/showcased`} data-attr={"button-explore-recentphotos"}>
                                            <ItemIcon><ClockIconLightShadowed scale={20}/></ItemIcon>
                                            <span>Recent</span>
                                        </DropdownItemClickable>
                                        <DropdownDivider/>
                                        <SectionMenuHeader>Stories</SectionMenuHeader>
                                        <DropdownItemClickable to={`/explore/recent/stories`} data-attr={"button-explore-recentstories"}>
                                            <ItemIcon><StoryIconLightShadowed scale={20}/></ItemIcon>
                                            <span>Recent</span>
                                        </DropdownItemClickable>
                                    </DropdownMenu>
                                </Dropdown>
                                {props.appState.user ?
                                    <>
                                        <Dropdown as={NavItem} key={"mywork"}>
                                            <Dropdown.Toggle as={NavLink} data-attr={"menu-mywork"}>
                                                <TopIcon skinny={skinny}><CameraIconLightShadowed scale={skinny ? 24 : 20}/></TopIcon>
                                                {skinny ? null : <TopText>My Work</TopText>}
                                            </Dropdown.Toggle>
                                            <DropdownMenu skinny={skinny}>
                                                {skinny ? <SectionMenuHeader>My Work</SectionMenuHeader> : null}
                                                <DropdownItemClickable to={`${userUrl}`} data-attr={"button-mywork-profile"}>
                                                    <ItemIcon><ProfileIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>Profile</span>
                                                </DropdownItemClickable>
                                                <DropdownDivider/>
                                                <SectionMenuHeader>Photos</SectionMenuHeader>
                                                <DropdownItemClickable to={`${userUrl}/showcase`} data-attr={"button-mywork-showcase"}>
                                                    <ItemIcon><ShowcaseIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>Showcase</span>
                                                </DropdownItemClickable>
                                                <DropdownItemClickable to={`${userUrl}/photos`} data-attr={"button-mywork-publicphotos"}>
                                                    <ItemIcon><GalleryIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>Public</span>
                                                </DropdownItemClickable>
                                                <DropdownItemClickable to={`${userUrl}/photos/unpublished`} data-attr={"button-mywork-privatephotos"}>
                                                    <ItemIcon><GalleryIconLightShadowedLowOpacity scale={20}/></ItemIcon>
                                                    <span>Private</span>
                                                </DropdownItemClickable>
                                                <DropdownSpacer/>
                                                <DropdownItemClickable to={`/browse`} data-attr={"button-mywork-browse"}>
                                                    <ItemIcon><MagnifyIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>Browse</span>
                                                </DropdownItemClickable>
                                                <DropdownItemClickable to={`/upload`} data-attr={"button-mywork-upload"}>
                                                    <ItemIconWhite><PlusIconLightShadowed scale={20}/></ItemIconWhite>
                                                    <span>Upload</span>
                                                </DropdownItemClickable>
                                                <DropdownDivider/>
                                                <SectionMenuHeader>Stories</SectionMenuHeader>
                                                <DropdownItemClickable to={`${userUrl}/stories`} data-attr={"button-mywork-allstories"}>
                                                    <ItemIcon><ListIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>Show all</span>
                                                </DropdownItemClickable>
                                                <DropdownItemClickable to={`/story/_new`} data-attr={"button-mywork-newstory"}>
                                                    <ItemIcon><PlusIconLightShadowed scale={20}/></ItemIcon>
                                                    <span>New</span>
                                                </DropdownItemClickable>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </>
                                    :
                                    <Dropdown as={NavItem} key={"upload-login"}>
                                        <Dropdown.Toggle as={NavLink} data-attr={"menu-upload"}>
                                            <TopIcon skinny={skinny}><CameraIconLightShadowed scale={skinny ? 24 : 20}/></TopIcon>
                                            {skinny ? null : <TopText>Upload</TopText>}
                                        </Dropdown.Toggle>
                                        <DropdownMenu skinny={true}>
                                            <SectionMenuHeaderLogin>Log In&nbsp;<OrText>or</OrText><br/>Create Account</SectionMenuHeaderLogin>
                                            <LoginInfoDropdownItemClickable data-attr={"button-upload-google"}
                                                onClick={() => loginWith("google")}
                                            >
                                                <WithSpan>with</WithSpan> Google
                                            </LoginInfoDropdownItemClickable>
                                            <LoginInfoDropdownItemClickable data-attr={"button-upload-facebook"}
                                                onClick={() => loginWith("facebook")}>
                                                <WithSpan>with</WithSpan> Facebook
                                            </LoginInfoDropdownItemClickable>
                                            <LoginInfoDropdownItemClickable data-attr={"button-upload-amazon"}
                                                onClick={() => loginWith("amazon")}>
                                                <WithSpan>with</WithSpan> Amazon
                                            </LoginInfoDropdownItemClickable>
                                            <LoginInfoDropdownItemClickable data-attr={"button-upload-github"}
                                                onClick={() => loginWith("github")}>
                                                <WithSpan>with</WithSpan> Github
                                            </LoginInfoDropdownItemClickable>
                                            <DropdownSpacer/>
                                            <LogInInfo>
                                                <SectionMenuHeader>Features</SectionMenuHeader>
                                                <LogInItems>
                                                    <LogInItem><CheckmarkIconLightShadowedStyled holderVerticalAlign={"-2px"} scale={14}/><LoginItemText>Upload photos</LoginItemText></LogInItem>
                                                    <LogInItem><CheckmarkIconLightShadowedStyled holderVerticalAlign={"-2px"} scale={14}/><LoginItemText>Create stories</LoginItemText></LogInItem>
                                                    <LogInItem><CheckmarkIconLightShadowedStyled holderVerticalAlign={"-2px"} scale={14}/><LoginItemText>Post comments</LoginItemText></LogInItem>
                                                    <LogInItem><CheckmarkIconLightShadowedStyled holderVerticalAlign={"-2px"} scale={14}/><LoginItemText>Save favorites</LoginItemText></LogInItem>
                                                </LogInItems>
                                            </LogInInfo>
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                                {/* *scaling* */
                                  /*<DumbLinkContainer onClick={(e) => { if (isMap) { e.preventDefault(); }}} to={"/map"}>
                                    <NavLink>
                                        <TopIcon skinny={skinny}><EarthIconLightShadowed scale={skinny ? 24 : 20}/></TopIcon>
                                        {skinny ? null : <TopText>Map</TopText>}
                                    </NavLink>
                                </DumbLinkContainer>*/}
                            </NavStyled>
                            <NavStyled className="ml-auto">
                                <Dropdown as={NavItem} key={"help"}>
                                    <Dropdown.Toggle as={NavLink} data-attr={"menu-help"}>
                                        {skinny ? null : <TopText>Help</TopText> }
                                        <TopIconRight lowOpacity={true} skinny={skinny}><HelpIconLightShadowed scale={skinny ? 24 : 20}/></TopIconRight>
                                    </Dropdown.Toggle>
                                    <DropdownMenu skinny={skinny}>
                                        <DropdownItem target={"_blank"} href={helpTarget} data-attr={"button-help-helpdocs"}>
                                            <span>Help Docs</span><LinkIconDiv><ExternalLinkIconLightShadowed scale={14}/></LinkIconDiv>
                                        </DropdownItem>
                                        <DropdownDivider/>
                                        <SectionMenuHeader>Community</SectionMenuHeader>
                                        <DropdownItem target={"_blank"} href={"https://discord.gg/E5YpJu4yhs"} data-attr={"button-help-discord"}>
                                            <span>Discord</span><LinkIconDiv><ExternalLinkIconLightShadowed scale={14}/></LinkIconDiv>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <LoginFadeInDiv invisible={!props.appState.initialUserLoad}>
                                    {!props.appState.initialUserLoad ? <LoginSpacer/> :
                                        (props.appState.user ?
                                                <Dropdown as={NavItem} key={"name"}>
                                                    <Dropdown.Toggle as={skinny ? NavLinkUserSkinny : NavLinkUser} data-attr={"menu-user"}>
                                                        {skinny ?
                                                            <UserImageNavbarSkinnyComponent blackAndWhite={true}
                                                                                            scale={28}
                                                                                            filename={props.appState.user.imageFilename}/>
                                                            :
                                                            <RightToLeftDiv>
                                                                <UserName>{props.appState.user.name}</UserName>
                                                                <UserImageNavbarComponent blackAndWhite={true}
                                                                                          scale={28}
                                                                                          filename={props.appState.user.imageFilename}/>
                                                            </RightToLeftDiv>
                                                        }
                                                    </Dropdown.Toggle>
                                                    <DropdownMenuRight skinny={skinny}>
                                                        <DropdownItemClickable to={`${userUrl}`} data-attr={"button-user-profile"}>
                                                            <span>Profile</span>
                                                        </DropdownItemClickable>
                                                        <DropdownSpacer/>
                                                        <DropdownItemClickable to="/favorites/photos" data-attr={"button-user-favorites"}>
                                                            <span>Favorites</span>
                                                        </DropdownItemClickable>
                                                        <DropdownItemClickable to="/following" data-attr={"button-user-following"}>
                                                            <span>Following</span>
                                                        </DropdownItemClickable>
                                                        <DropdownItemClickable to="/followers" data-attr={"button-user-followers"}>
                                                            <span>Followers</span>
                                                        </DropdownItemClickable>
                                                        <DropdownSpacer/>
                                                        <DropdownItemClickable to="/settings/account" data-attr={"button-user-account"}>
                                                            <span>Settings</span>
                                                        </DropdownItemClickable>
                                                        <DropdownDivider/>
                                                        <DropdownItemClickable onClick={logout} data-attr={"button-user-logout"}>
                                                            <span>Log out</span>
                                                        </DropdownItemClickable>
                                                    </DropdownMenuRight>
                                                </Dropdown>
                                                :
                                                <Dropdown as={NavItem} key={"login"}>
                                                    <Dropdown.Toggle as={NavLink} data-attr={"menu-login"}>
                                                        <TopText thin={skinny}>Log In</TopText>
                                                        {!skinny ?
                                                            <TopIconRight lowOpacity={true}
                                                                          skinny={skinny}><LogInIconLightShadowed
                                                                scale={skinny ? 24 : 20}/></TopIconRight>
                                                            : null
                                                        }
                                                    </Dropdown.Toggle>
                                                    <DropdownMenuRightWide skinny={skinny}>
                                                        <DropdownItemClickable data-attr={"button-login-google"}
                                                            onClick={() => loginWith("google")}>
                                                            <WithSpan>with</WithSpan> Google
                                                        </DropdownItemClickable>
                                                        <DropdownItemClickable data-attr={"button-login-facebook"}
                                                            onClick={() => loginWith("facebook")}>
                                                            <WithSpan>with</WithSpan> Facebook
                                                        </DropdownItemClickable>
                                                        <DropdownItemClickable data-attr={"button-login-amazon"}
                                                            onClick={() => loginWith("amazon")}>
                                                            <WithSpan>with</WithSpan> Amazon
                                                        </DropdownItemClickable>
                                                        <DropdownItemClickable data-attr={"button-login-github"}
                                                            onClick={() => loginWith("github")}>
                                                            <WithSpan>with</WithSpan> Github
                                                        </DropdownItemClickable>
                                                        <DropdownDivider/>
                                                        <DropdownItemClickable to={`/recovery`} data-attr={"button-login-recovery"}>
                                                            Recover Account
                                                        </DropdownItemClickable>
                                                    </DropdownMenuRightWide>
                                                </Dropdown>
                                        )
                                    }
                                </LoginFadeInDiv>
                                <Spacer/>
                            </NavStyled>
                        </Navbar.Collapse>
                    </NavbarStyled>
                </FontLoadFadeIn>
            </NavbarHolder>
        </>
    );

}

export default inject('appState')(observer(AppNavbar));