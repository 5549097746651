import removeAccents from 'remove-accents'

export const arraysEqual = (a, b) => a.length === b.length && a.every((item, index) => b[index] === item);

export const getNested = (obj, ...args) => args.reduce((obj, level) => obj && obj[level], obj);

export const urlTitleSegment = (title) => {
    if (title !== null && title !== undefined) {
        return "/" + removeAccents(title).replace(/[\s_]+/g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    } else {
        return "";
    }
};

export const userPath = (user) => {
    if (user.alias) {
        return `/${user.alias}`;
    } else {
        return `/user/_${user.ident}`;
    }
}

export const validCoords = (coords) => {
    // *scaling*
    return false && !!coords && coords.lat !== undefined && coords.lat !== null && coords.lon !== undefined && coords.lon !== null;
}
